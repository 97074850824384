<template>
  <b-row>
    <b-col md="12">
      <create-report :refresh-data="getReports" />
    </b-col>
    <b-col>
      <b-card
        title="Geçmiş Raporlar"
        no-body
      >
        <b-card-header>
          <b-card-title>Geçmiş Raporlar</b-card-title>
        </b-card-header>
        <template v-if="dataList.length > 0">
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            hover
          >
            <template #cell(username)="data">
              {{ data.item.username }}
              <div v-if="data.item.created">
                <small class="text-warning">{{ moment(data.item.created).format('DD.MM.YYYY') }}</small>
              </div>
            </template>
            <template #cell(control)="data">
              <b-button
                size="sm"
                variant="success"
                :href="downloadUrl(data.item.report_code)"
                target="_blank"
              >
                <feather-icon icon="SaveIcon" />
                İndir
              </b-button>

            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </template>
        <template v-else>
          <b-alert
            show
            variant="info"
            class="m-2"
          >
            <div class="alert-body text-center">
              Kayıtlı veri bulunmuyor.
            </div>
          </b-alert>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BPagination, BCardFooter, BCardHeader, BCardTitle, BAlert, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CreateReport from '@/views/Reports/Rental/ExpenseReport/CreateReport.vue'

export default {
  name: 'ExpenseReport',
  components: {
    BAlert,
    BCardFooter,
    BCardHeader,
    BCardTitle,
    BPagination,
    BTable,
    BRow,
    BCol,
    BCard,
    BButton,
    CreateReport,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'title',
          label: 'Başlık',
          thClass: 'align-middle',
        },
        {
          key: 'report_code',
          label: 'Rapor No',
          thClass: 'align-middle',
        },
        {
          key: 'username',
          label: 'Tarih',
          thClass: 'align-middle',
        },
        {
          key: 'control',
          label: '',
          thClass: 'align-middle',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        select: [
          'com_rental_expense_report.id AS id',
          'com_rental_expense_report.title AS title',
          'com_rental_expense_report.report_code AS report_code',
          'com_user.name AS username',
          'com_rental_expense_report.created AS created',
        ],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalExpense/dataList']
    },
    itemData() {
      return this.$store.getters['rentalExpense/itemData']
    },
    dataCount() {
      return this.$store.getters['rentalExpense/dataCounts']
    },
    saveStatus() {
      return this.$store.getters['rentalExpense/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getReports()
  },
  methods: {
    getReports() {
      this.$store.dispatch('rentalExpense/getDataList', this.dataQuery)
    },
    downloadUrl(reportCode) {
      return `${this.$store.state.app.baseURL}/exports/rental/expense?token=${localStorage.getItem('downloadToken')}&report_code=${reportCode}`
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
