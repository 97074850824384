<template>
  <b-card title="Rapor Oluştur">
    <b-form-group
      label="Başlık"
      label-for="title"
    >
      <b-form-input
        v-model="itemData.title"
        placeholder="Giriniz"
      />
    </b-form-group>
    <b-form-group
      label="Plaka Listesi"
      label-for="plates"
    >
      <b-form-textarea
        id="plates"
        v-model="plateList"
        rows="10"
        placeholder="Plaka Listesi"
      />
    </b-form-group>
    <b-button
      variant="success"
      :disabled="!itemData.plates.length || !itemData.title"
      @click="saveData"
    >
      <FeatherIcon icon="SaveIcon" />
      Oluştur
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BFormGroup, BFormTextarea, BButton, BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'CreateReport',
  components: {
    BCard,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormInput,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      plateList: null,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['rentalExpense/itemData']
    },
  },
  watch: {
    plateList(val) {
      if (val) {
        this.itemData.plates = val.split('\n')
      } else {
        this.itemData.plates = []
      }
    },
  },
  methods: {
    saveData() {
      this.$store.dispatch('rentalExpense/saveData', this.itemData)
        .then(response => {
          if (response.status) {
            this.refreshData()
            const url = `${this.$store.state.app.baseURL}/exports/rental/expense?token=${localStorage.getItem('downloadToken')}&report_code=${response.report_code}`
            window.open(url, '_blank')
          }
        })
    },
  },
}
</script>
